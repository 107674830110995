import { useEffect, useState, useRef } from "react";
import LayoutWrapper from "../components/layout";
import RecentNotes from "features/recentNotes";
import Main from "components/main";
import NoteContainer from "features/noteContainer";
import SIPUsers from "features/SIPUsers";
import { useQuery } from "@tanstack/react-query";
import API from "api/index";
import { useAppSelector } from "store/index";
import ContainerHeader from "features/noteContainer/containerHeader/ContainerHeader";
import { useQueryParams } from "hooks/useQueryParams";
import { ITicket } from "constants/Interfaces/notesInterface";
import useGetNoteCountofDay from "hooks/useGetNoteCountofDay";
import NotesOverview from "features/notesOverviewSidebar";

export enum TabStatus {
  TIMELINE = "TIMELINE",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  UNMANAGED = "UNMANAGED",
  MISSCALL = "MISSCALL",
}
export interface ITabStatus {
  tab: keyof typeof TabStatus;
}

const Homepage = () => {
  const noteCountForTimeline = useGetNoteCountofDay(TabStatus.TIMELINE);
  const noteCountForOpen = useGetNoteCountofDay(TabStatus.OPEN);
  const noteCountForUnmanaged = useGetNoteCountofDay(TabStatus.UNMANAGED);
  const noteCountForClosed = useGetNoteCountofDay(TabStatus.CLOSED);
  const noteCountForMisscall = useGetNoteCountofDay(TabStatus.MISSCALL);
  const SwitchTabContent = [
    {
      value: TabStatus.TIMELINE,
      name: "Timeline",
      count: noteCountForTimeline,
    },
    {
      value: TabStatus.OPEN,
      name: "Open",
      count: noteCountForOpen,
    },
    {
      value: TabStatus.UNMANAGED,
      name: "Unmanaged",
      count: noteCountForUnmanaged,
    },
    {
      value: TabStatus.CLOSED,
      name: "Closed",
      count: noteCountForClosed,
    },
    {
      value: TabStatus.MISSCALL,
      name: "Misscall",
      count: noteCountForMisscall,
    },
  ];
  const [items, setItems] = useState<Record<TabStatus, ITicket[]>>({
    [TabStatus.TIMELINE]: [],
    [TabStatus.OPEN]: [],
    [TabStatus.UNMANAGED]: [],
    [TabStatus.CLOSED]: [],
    [TabStatus.MISSCALL]: [],
  });
  const { getQueryParam, setQueryParam } = useQueryParams<ITabStatus>();
  let tabStatus = getQueryParam("tab");
  const [pages, setPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { isSalesUser, currentUser } = useAppSelector(
    (state) => state.authUserReducer
  );

  useEffect(() => {
    setQueryParam("tab", TabStatus.TIMELINE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userID = isSalesUser
    ? currentUser?.type !== "ADMIN"
      ? currentUser?.id
      : undefined
    : undefined;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = useQuery({
    queryKey: ["batchNotes", tabStatus, pages],
    queryFn: async () => {
      try {
        const res = await API.post("/notes/batch", {
          page: pages,
          status: tabStatus === TabStatus.TIMELINE ? undefined : tabStatus,
          channel: isSalesUser ?? "SALES",
          userID,
        });
        
        if (res?.data?.notes?.length > 0) {
          const newNotes: ITicket[] = res?.data?.notes?.filter(
            (newNote: ITicket) => {
              // Check if the new note has a different ID or content
              return !items[tabStatus || TabStatus.TIMELINE].some(
                (existingNote: ITicket) => existingNote.id === newNote.id
              );
            }
          );

          setItems({
            ...items,
            [tabStatus || TabStatus.TIMELINE]: [
              ...items[tabStatus || TabStatus.TIMELINE],
              ...newNotes,
            ],
          });
          return [...items[tabStatus || TabStatus.TIMELINE], ...newNotes];
        } else {
          setHasMore(false);
          return [];
        }
      } catch (e) {
        console.log(e);
        setHasMore(false);
      }
    },
  });

  const handleNext = () => {
    if (pages <= 12) {
      setPages((prevPage) => prevPage + 1);
    }
  };

  // Create ref array for all items
  // Assign to each overview item to focus

  // const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(
      0,
      items[tabStatus || TabStatus.TIMELINE].length
    );
  }, [items, tabStatus]);

  const handleClick = (noteId: string) => {
    itemRefs.current[parseInt(noteId)]?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <LayoutWrapper>
      <RecentNotes className="hidden lg:inline-block" />
      <Main>
        <ContainerHeader
          tabStatus={tabStatus}
          tabList={SwitchTabContent}
          onTabChange={(e: any) => {
            console.log(e);
            setQueryParam("tab", e);
            setPages(1);
          }}
          showAddNoteMode
        />
        <div className="flex flex-1 flex-row w-full">
          <NoteContainer
            hasMore={hasMore}
            handleNext={handleNext}
            noteList={items[tabStatus || TabStatus.TIMELINE]}
            itemRefs={itemRefs}
          />
        </div>
      </Main>
      <NotesOverview
        notesList={items[tabStatus ?? TabStatus.TIMELINE]}
        handleClick={handleClick}
      />
      <SIPUsers className="hidden lg:inline-block" />
    </LayoutWrapper>
  );
};

export default Homepage;
