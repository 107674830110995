import SelectComponent from "components/select";
import Select from "react-select";
import {
  PhoneIncoming,
  PhoneOutgoing,
  Mail,
  MessageSquareIcon,
  TicketIcon,
  UserPlus,
  X,
  Edit,
  Merge,
  PanelBottom,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import {
  IBusiness,
  ITicket,
} from "../../../constants/Interfaces/notesInterface";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store";
import API from "../../../api";
import {
  changeNoteAssignedTo,
  deleteNoteByID,
  updateNoteStatus,
} from "store/notes/note_action";
import { NoteStatus } from "../../../constants/noteStatus";
import { updateBusinessByNoteId } from "../../../store/notes/note_business_action";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import moment from "moment";
import Modal from "components/modal";
import InlineDelete from "components/inlineDelete";
// import { sendEmail } from "store/business/businessAction";
import { sendSms, sendEmail, storeComment } from "store/notes/comment_action";
import Accordion from "../components/accordion";
import * as RadixAccordion from "@radix-ui/react-accordion";
// import { getMessage } from "@reduxjs/toolkit/dist/actionCreatorInvariantMiddleware";
import { PSTtoLocal } from "utils/dateConverter";

interface INoteCardHeaderProps {
  notes: ITicket;
}

const NoteCardHeader = ({ notes }: INoteCardHeaderProps) => {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [mergeModal, setMergeModal] = useState<boolean>(false);
  const [ticketCloseModal, setTicketCloseModal] = useState(false);
  const { currentUser, isSalesUser, user_role } = useAppSelector(
    (state) => state.authUserReducer
  );
  const navigate = useNavigate();
  const { userList } = useAppSelector((state) => state.homeReducer);
  const { businessList } = useAppSelector((state) => state.businessReducer);
  const businessOptionsList = businessList.map((business: IBusiness) => {
    return {
      value: business.id,
      label: business.name,
    };
  });
  const [selectedMergingNotes, setSelectedMergingNotes] = useState<ITicket[]>(
    []
  );
  const [confirmTicketClose, setConfirmTicketClose] = useState(false);
  const [currentNoteStatus, setCurrentNoteStatus] = useState(notes?.status);
  const [closingTicketMessage, setClosingTicketMessage] = useState("");
  const [showMessageOnBottom, setShowMessageOnBottom] = useState(true);

  useEffect(() => {
    setCurrentNoteStatus(notes?.status);
  }, [notes.status]);

  const {
    data: similarNotes,
    refetch,
    isLoading,
  } = useQuery(
    ["similarNotes", notes.contactID, notes.businessID, notes.type],
    async () => {
      const res = await API.get(
        `notes/similarNotes/${notes.contactID}/${notes.businessID}/${notes.type}/${notes.createdAt}/${notes.id}`
      );
      console.log("Similar Notes", res.data);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      // Fetch on button click
      enabled: false,
    }
  );

  const businessAssignToNoteMutation = useMutation(updateBusinessByNoteId, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["batchNotes"] });
      queryClient.invalidateQueries({ queryKey: ["businessBatchNotes"] });
    },
  });

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleMerge = async () => {
    if (selectedMergingNotes.length === 0) {
      toast.error("Please select notes to merge");
      return;
    } else {
      const notesToMerge = selectedMergingNotes.map((note) => {
        return {
          noteID: note.id,
          contactID: note.contactID,
          type: note.type,
          createdAt: note.createdAt,
          userID: currentUser?.id,
          content: note.content,
          channel: isSalesUser ? "SALES" : undefined,
          ticketNumber: note.ticketNumber,
          ROWID: note.id,
          details: JSON.stringify({
            createdAt: note.createdAt,
            type: note.type,
            contact: note.Contact,
            comments: note.Comments?.map((x: any) => x.id),
          }),
        };
      });
      // Send the note itself and the selected note to merge
      toast.promise(
        API.post("notes/merge", {
          mergingNotes: [
            ...notesToMerge,
            {
              noteID: notes.id,
              contactID: notes.contactID,
              type: notes.type,
              createdAt: notes.createdAt,
              userID: currentUser?.id,
              content: notes.content,
              channel: isSalesUser ? "SALES" : undefined,
              ticketNumber: notes.ticketNumber,
              ROWID: notes.id,
              details: JSON.stringify({
                createdAt: notes.createdAt,
                type: notes.type,
                contact: notes.Contact,
                comments: notes.Comments?.map((x: any) => x.id),
              }),
            },
          ],
        }),
        {
          loading: "Merging Notes",
          success: () => {
            setMergeModal(false);
            return "Notes merged";
          },
          error: (error) => {
            setMergeModal(false);
            return `Error: ${error.response.data.message}`;
          },
        }
      );
    }

    setSelectedMergingNotes([]);
  };

  const handleClickOnSuggestion = async (business: any) => {
    console.log({ business });
    toast.promise(
      businessAssignToNoteMutation.mutateAsync({
        businessId: business.value,
        noteId: notes?.id,
        contactId: notes?.contactID,
      }),
      {
        loading: `Assigning Note to ${business.label}`,
        success: () => `Note Assigned to ${business.label}`,
        error: (error) => `Error: ${error}`,
      }
    );

    setIsEditing(false);
  };

  const handleNoteAssignChange = async (selectedUser: any) => {
    toast.promise(
      changeNoteAssignedTo({
        userUUID: selectedUser.id,
        noteUUID: notes?.id,
        from_user:
          notes?.User && `${notes?.User?.first_name} ${notes?.User?.last_name}`,
        to_user: selectedUser.label,
      }),
      {
        loading: `Assigning Note to ${selectedUser.label}`,
        success: () => `Note Assigned to ${selectedUser.label}`,
        error: (error) => `Error: ${error}`,
      }
    );
  };

  const handleNoteDelete = () => {
    toast.promise(deleteNoteByID(notes), {
      loading: `Deleting Note`,
      success: () => `Note Deleted`,
      error: (error) => `Error: ${error}`,
    });
  };

  const getSMSCloseMessage = () =>
    `${showMessageOnBottom ? "" : closingTicketMessage}` +
    `${showMessageOnBottom ? "Hi, " : ""}Your Growthzilla support ticket ${
      notes?.ticketNumber ? notes.ticketNumber : ""
    } is now closed.

${showMessageOnBottom ? closingTicketMessage : ""}
`.trim() +
    `

I'd love to hear your feedback. Please share your review here.
https://proma.ai/public/tb/18160000001985617/form/18160000001985631?tid=${
      notes.ticketNumber ? notes.ticketNumber : ""
    }

Thank you for your cooperation.`;

  const handleNoteStatusChange = async (selectedNoteStatus: any) => {
    let firstCallResolution;

    if (selectedNoteStatus.id === NoteStatus.CLOSED) {
      // Send reply for CLOSED email/sms tickets
      switch (notes?.type) {
        case "SMS":
        case "CALL":
        case "CALL_OUT":
          await storeComment({
            ...{
              content:
                `SMS sent to ${notes.Contact.phone}
                
                ` + getSMSCloseMessage(),
              attachments: [],
            },
            noteStatus: notes?.status,
            noteId: notes?.id,
            contactNumber: notes?.Contact?.phone,
            // replySMSorEmail: true,
            emailId: notes?.Contact?.email,
            noteType: "EMAIL",
            noteAssigned: notes?.userID,
          });

          toast.promise(
            sendSms({
              contactNumber: notes?.Contact?.phone,
              content: getSMSCloseMessage(),
              smsFrom: isSalesUser ? currentUser?.phone : "",
            }),
            {
              loading: `Sending SMS to ${notes?.Contact?.phone} - ${notes?.Business.name}`,
              success: "Ticket Close Send SMS",
              error: (error) => `Error: ${error}`,
            }
          );
          break;

        case "EMAIL":
          toast.promise(
            sendEmail({
              emailId: notes?.Contact.email,
              noteId: notes?.id,
              ticketNumber: notes?.ticketNumber ? notes.ticketNumber : notes.id,
              content: `
              Dear Valued Customer,<br/><br/>
              We are writing to inform you that your Growthzilla support ticket ${
                notes.ticketNumber ? notes.ticketNumber : ""
              } has now been closed. We appreciate your patience and cooperation throughout the resolution process.
              <br/><br/>

              ${closingTicketMessage}
              <br/><br/>

              Your feedback is incredibly valuable to us, as it helps us continually improve our services.
              We would greatly appreciate it if you could take a few moments to share your experience by writing a review.
              <a href="https://proma.ai/public/tb/18160000001985617/form/18160000001985631?tid=${
                notes.ticketNumber ? notes.ticketNumber : ""
              }">Share Feedback</a>
              <br/><br/>

              Should you have any further questions or require additional assistance, 
              please feel free to reach out to us at any time by replying directly to this email or contact our support team at supporttest@growthzilla.com. 
              We are always here to help and ensure your satisfaction.
              <br/><br/>

              Thank you for your cooperation.
              <br/><br/>

              ${
                notes.User?.first_name
                  ? notes.User?.first_name +
                    " " +
                    notes.User?.last_name +
                    "<br/>"
                  : ""
              }
              Growthzilla Support Team
              `,
            }),
            {
              loading: `Sending Ticket Close Email to ${
                notes?.Contact?.email ? notes?.Contact?.email : ""
              }`,
              success: "Ticket Close Email sent",
              error: (error) => `Error: ${error}`,
            }
          );
          break;
      }

      // Check for first call resolution
      if (notes.type === "CALL" || notes.type === "EMAIL") {
        // If same agent closes ticket and closing time is less than 5 minutes          console.log(
        console.log(Date.now() - PSTtoLocal(notes.createdAt).getTime() / 60000);

        if (
          notes?.userID === currentUser?.id &&
          Date.now() - PSTtoLocal(notes.createdAt).getTime() < 15 * 60 * 1000
        ) {
          firstCallResolution = true;
        } else {
          firstCallResolution = false;
        }
      }
    }

    // Update ticket status
    toast.promise(
      updateNoteStatus({
        noteStatusUpdateAt: moment()
          .tz("America/Los_Angeles")
          .format("YYYY-MM-DD HH:mm:ss"),
        updatedNoteStatus: selectedNoteStatus.id,
        currentNoteStatus: notes.status,
        noteId: notes?.id,
        noteType: notes.type,
        firstCallResolution,
      }),
      {
        loading: `Changing Note Status to ${selectedNoteStatus.id}`,
        success: () => `Note Status Changed to ${selectedNoteStatus.id}`,
        error: (error) => `Error: ${error}`,
      }
    );
  };

  const userSelectOptions = userList?.map((user: any) => ({
    label: `${user.first_name} ${user.last_name}`,
    id: user.id,
  }));

  return (
    <div
      className={`flex h-[48px] w-full justify-between group rounded-t-md ${
        currentNoteStatus === "OPEN"
          ? "  bg-[#f9d7bf]"
          : currentNoteStatus === "UNMANAGED"
          ? " bg-[#bfc0f9]"
          : "bg-[#edc7f7]"
      } px-4`}
    >
      <div className="flex items-center gap-2">
        {notes?.type === "CALL" ? (
          <PhoneIncoming size={18} className="stroke-[1.5]" />
        ) : notes?.type === "CALL_OUT" ? (
          <PhoneOutgoing size={18} className="stroke-[1.5] " />
        ) : notes?.type === "EMAIL" ? (
          <Mail size={18} className="stroke-[1.5] " />
        ) : notes?.type === "SMS" ? (
          <MessageSquareIcon size={18} className="stroke-[1.5] " />
        ) : (
          <TicketIcon size={18} className="stroke-[1.5] " />
        )}
        <div
          // onKeyDown={handleKeyPress}
          className="group flex items-center justify-center"
        >
          {isEditing ? (
            <div className="flex gap-2">
              <Select
                className="md:w-[320px] w-[180px] "
                options={businessOptionsList}
                onChange={(e) => handleClickOnSuggestion(e)}
              />
              <button
                onClick={() => setIsEditing(false)}
                className="text-black/40 hover:text-black/70"
              >
                <X size={16} />
              </button>
            </div>
          ) : (
            <div className="flex lg:px-2 py-2 gap-1 lg:gap-4 items-center text-[14px] md:text-[16px] ">
              <button
                onClick={() => {
                  if (!notes?.businessID) {
                    toast.error("Business not found");
                  } else {
                    navigate(`/app/business/${notes?.businessID}?tab=TIMELINE`);
                  }
                }}
              >
                {notes?.Business?.name ?? "UNKNOWN BUSINESS"}
              </button>
              <button
                onClick={handleClick}
                className=" w-6 h-6 rounded-sm justify-center items-center flex text-black/40 hover:text-black/70"
              >
                <Edit size={16} />
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Right-side UI */}
      <div className="flex gap-1 lg:gap-4 font-medium text-[14px] items-center">
        {["CALL", "CALL_OUT", "EMAIL", "SMS"].includes(notes?.type) && (
          <button
            onClick={() => {
              console.log("Merge button clicked");
              refetch();
              setMergeModal(true);
            }}
            className="text-[14px] text-black/40  hover:text-black/70  mr-2 inline-flex gap-2 items-center"
          >
            <Merge size={16} className="rotate-180" />
            <p className="hidden lg:inline-block">Merge Note</p>
          </button>
        )}
        <div className="flex text-black/40 hover:text-black/70 items-center">
          <UserPlus size={16} />
          <SelectComponent
            searchable
            placeholder="Assign To"
            value={notes?.userID}
            options={userSelectOptions}
            onValueChange={(selectedUser: any) =>
              handleNoteAssignChange(selectedUser)
            }
          />
        </div>
        <div className="flex text-black/40 hover:text-black/70 items-center">
          <TicketIcon size={16} />
          <SelectComponent
            placeholder="Set Status"
            value={notes?.status}
            options={[
              { id: NoteStatus.OPENED, label: "OPEN" },
              { id: NoteStatus.CLOSED, label: "CLOSED" },
              { id: NoteStatus.UNMANAGED, label: "UNMANAGED" },
            ]}
            onValueChange={(selectedNoteStatus: any) => {
              if (
                selectedNoteStatus.id === NoteStatus.CLOSED &&
                ["CALL", "CALL_OUT", "SMS", "EMAIL"].includes(notes.type)
              ) {
                setTicketCloseModal(true);
              } else {
                setCurrentNoteStatus(selectedNoteStatus.id);
                setConfirmTicketClose(false);
                handleNoteStatusChange(selectedNoteStatus);
              }
            }}
            confirmTicketClose={confirmTicketClose}
          />
        </div>
        {user_role.includes("Admin") && (
          <InlineDelete
            className="text-black/40"
            title="Delete"
            onAccept={() => handleNoteDelete()}
          />
        )}
      </div>

      {/* Modal for Merging notes */}
      <Modal
        isOpen={mergeModal}
        onClose={() => setMergeModal(false)}
        className="w-1/2 max-h-[60%] overflow-y-auto"
      >
        <h1 className="font-semibold text-lg text-primary px-5 mb-2">
          Select notes to merge
        </h1>
        <RadixAccordion.Root
          type="multiple"
          className="flex flex-col gap-0.5 overflow-y-scroll shadow-[0_2px_10px] shadow-black/5"
        >
          {(similarNotes && similarNotes.length > 0) || isLoading ? (
            <>
              {isLoading ? (
                <p className="text-center my-6 pb-3">Loading notes ...</p>
              ) : (
                <>
                  <Accordion
                    note={notes}
                    setSelectedMergingNotes={setSelectedMergingNotes}
                    defaultChecked
                  />
                  {similarNotes.map((note: any) => (
                    <Accordion
                      key={note?.id}
                      note={note}
                      setSelectedMergingNotes={setSelectedMergingNotes}
                    />
                  ))}
                </>
              )}
              <div className="flex gap-6 w-full mt-4 px-4 justify-center">
                <button
                  onClick={() => setMergeModal(false)}
                  className="min-w-[64px] w-full rounded-md font-medium px-2 py-1 border border-purple-950  text-purple-950 "
                >
                  Cancel
                </button>
                <button
                  onClick={handleMerge}
                  className="min-w-[64px] w-full rounded-md font-medium px-2 py-1  bg-purple-950  text-white "
                >
                  Merge
                </button>
              </div>
            </>
          ) : (
            <>
              <p className="text-center w-full my-6 pb-3">
                No similar notes found
              </p>
              <button
                onClick={() => setMergeModal(false)}
                className="rounded-md font-medium px-2 mx-12 py-1 border border-purple-950  text-purple-950 "
              >
                Cancel
              </button>
            </>
          )}
        </RadixAccordion.Root>
      </Modal>

      {/* Modal for Ticket Close Confirmation */}
      <Modal
        isOpen={ticketCloseModal}
        onClose={() => handleNoteStatusChange}
        closeOnClickOutside={true}
      >
        <div className="px-5 mb-8 text-center">
          <h1 className="font-semibold text-lg text-primary mb-4">
            Are you sure you want to close this ticket?
          </h1>
          <p className="">
            Closing this ticket will send a{" "}
            {notes.type === "EMAIL"
              ? "EMAIL"
              : notes.type === "CALL" ||
                notes.type === "SMS" ||
                notes.type === "CALL_OUT"
              ? "SMS"
              : null}{" "}
            to {notes.Contact?.name ? notes.Contact.name : "user"} notifying
            that the ticket has been closed with the following message.
          </p>
          <div>
            <textarea
              className="w-full h-24 mt-4 p-2 border border-black/20 rounded-md"
              placeholder="Write your closing message here"
              value={closingTicketMessage}
              onChange={(e) => {
                setClosingTicketMessage(e.target.value);
              }}
            ></textarea>

            <div className="relative">
              <textarea
                className="w-full h-56 mt-4 p-2 border border-black/20 rounded-md"
                value={
                  notes.type === "EMAIL"
                    ? `Dear Valued Customer,

We are writing to inform you that your Growthzilla support ticket ${
                        notes?.ticketNumber
                      } has now been closed. We appreciate your patience and cooperation throughout the resolution process.

${closingTicketMessage ? closingTicketMessage : "[ USER MESSAGE ]"}

Your feedback is incredibly valuable to us, as it helps us continually improve our services. We would greatly appreciate it if you could take a few moments to share your experience by writing a review. Share Feedback

Should you have any further questions or require additional assistance, please feel free to reach out to us at any time by replying directly to this email or contact our support team at supporttest@growthzilla.com. We are always here to help and ensure your satisfaction.

Thank you for your cooperation.

${
  notes.User?.first_name
    ? notes.User?.first_name + " " + notes.User?.last_name
    : ""
}
Growthzilla Support Team`
                    : `${
                        showMessageOnBottom
                          ? ""
                          : closingTicketMessage
                          ? closingTicketMessage
                          : "[ YOUR MESSAGE ] "
                      }

${showMessageOnBottom ? "Hi," : ""}Your Growthzilla support ticket ${
                        notes.ticketNumber ? notes.ticketNumber : ""
                      } is now closed. 
`.trim() +
                      `
${
  showMessageOnBottom
    ? closingTicketMessage
      ? `\n${closingTicketMessage}\n`
      : "\n[ YOUR MESSAGE ]\n"
    : ""
}
` +
                      `
I'd love your feedback. Please share your review here.
https://proma.ai/public/tb/18160000001985617/form/18160000001985631?tid=${
                        notes.ticketNumber ? notes.ticketNumber : ""
                      }
                
Thank you for your cooperation.
${isSalesUser ? currentUser?.phone : "Growthzilla Support Team"}`.trim()
                }
                disabled
              />
              {notes.type !== "EMAIL" && (
                <div
                  className={`rounded-full w-8 h-8 flex items-center justify-center absolute right-5 top-8 cursor-pointer ${
                    showMessageOnBottom
                      ? "bg-[#3B0764]"
                      : "border-[#3B0764] border-2"
                  }`}
                  onClick={() => setShowMessageOnBottom((prev) => !prev)}
                >
                  <PanelBottom
                    className={`${
                      showMessageOnBottom ? "text-white" : "text-[#3B0764]"
                    }`}
                    size={20}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-6 w-full mt-4 px-4 justify-center">
          <button
            onClick={() => {
              setTicketCloseModal(false);
              setClosingTicketMessage("");
            }}
            className="min-w-[64px] w-full rounded-md font-medium px-2 py-1 border border-purple-950  text-purple-950 "
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setCurrentNoteStatus("CLOSED");
              setConfirmTicketClose(true);
              setTicketCloseModal(false);
              setClosingTicketMessage("");
              handleNoteStatusChange({ id: NoteStatus.CLOSED });
            }}
            className="min-w-[64px] w-full rounded-md font-medium px-2 py-1  bg-purple-950  text-white "
          >
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default NoteCardHeader;
